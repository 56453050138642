// Sass variable overrides here.

// Select BS variables overrides.
$form-select-padding-y:             0.9rem;
$form-select-padding-x:             0.9rem;
$form-select-font-family:           $input-font-family;
$form-select-font-size:             1rem;
$form-select-indicator-padding:     $form-select-padding-x * 3; // Extra padding for background-image
$form-select-font-weight:           $input-font-weight;
$form-select-line-height:           $input-line-height;
$form-select-color:                 $white;
$form-select-bg:                    transparent;
$form-select-disabled-color:        null;
$form-select-disabled-bg:           $gray-200;
$form-select-disabled-border-color: $input-disabled-border-color;
$form-select-bg-position:           right $form-select-padding-x center;
$form-select-bg-size:               16px 12px; // In pixels because image dimensions
$form-select-indicator-color:       $white;
$form-select-indicator:             url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$form-select-indicator-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/></svg>");

$form-select-feedback-icon-padding-end: $form-select-padding-x * 2.5 + $form-select-indicator-padding;
$form-select-feedback-icon-position:    center right $form-select-indicator-padding;
$form-select-feedback-icon-size:        $input-height-inner-half $input-height-inner-half;

$form-select-border-width:        $input-border-width;
$form-select-border-color:        $input-border-color;
$form-select-border-radius:       0;
$form-select-box-shadow:          0 0 0 0.25rem transparent;

$form-select-focus-border-color:  $blue-200;
$form-select-focus-box-shadow:    0 0 0 0.25rem $blue-200;

$form-select-padding-y-sm:        $input-padding-y-sm;
$form-select-padding-x-sm:        $input-padding-x-sm;
$form-select-font-size-sm:        $input-font-size-sm;
$form-select-border-radius-sm:    $input-border-radius-sm;

$form-select-padding-y-lg:        $input-padding-y-lg;
$form-select-padding-x-lg:        $input-padding-x-lg;
$form-select-font-size-lg:        $input-font-size-lg;
$form-select-border-radius-lg:    $input-border-radius-lg;

$form-select-transition:          $input-transition;

// Inputs
$input-padding-y:                       1rem;
$input-padding-x:                       1rem;
$input-font-size:                       1rem;
$input-focus-border-color:              $blue-200;
$input-focus-box-shadow:                0 0 0 0.25rem $blue-200;
$input-border-radius:                   0;
$input-border-radius-sm:                0;
$input-border-radius-lg:                0;
$input-bg:                              transparent;

// Checkboxes and Radio Buttons
$form-check-input-width:                  1em;
$form-check-min-height:                   $font-size-base * $line-height-base;
$form-check-padding-start:                $form-check-input-width + .5em;
$form-check-margin-bottom:                .125rem;
$form-check-label-color:                  null;
$form-check-label-cursor:                 pointer;
$form-check-transition:                   0.3s;

$form-check-input-active-filter:          brightness(90%);

$form-check-input-bg:                     transparent;
$form-check-input-border:                 1px solid $body-color;
$form-check-input-border-radius:          2px;
$form-check-radio-border-radius:          50%;
$form-check-input-focus-border:           $input-focus-border-color;
$form-check-input-focus-box-shadow:       $input-btn-focus-box-shadow;

$form-check-input-checked-color:          $component-active-color;
$form-check-input-checked-bg-color:       $component-active-bg;
$form-check-input-checked-border-color:   $form-check-input-checked-bg-color;
$form-check-input-checked-bg-image:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-checked-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/></svg>");
$form-check-radio-checked-bg-image:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-checked-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/></svg>");

// Bootstrap Form.
@import "~bootstrap/scss/forms";


// Custom form styles.
@import 'select/select';
@import 'fieldsets/fieldsets';
@import 'checkbox/checkbox';
@import 'radio/radio';


input {
  &::-webkit-calendar-picker-indicator {
    cursor: pointer;
    opacity: 0.4;
    filter: invert(1);
  }
}


.js-form-item {
  // International telephone field.
 .iti {
    display: block;
  }
}
