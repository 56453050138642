.activity-detail-page {

  &__top-content {
    &__mobile {
      display: none;
      @include media-breakpoint-down(lg) {
       display: block;
      }
    }
    &__mobile-title {
      margin-top: 1.25rem;
      display: none;
      @include media-breakpoint-down(lg) {
        display: block;
      }
      .field--name-field-subtitle {
        @extend .h3;
        color: var(--bs-gray-500);
      }
    }
  }


  &__activity-type-mobile {
    display: none;
    @include media-breakpoint-down(lg) {
      display: block;
    }
  }

  .field--name-field-activity-type {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 0.2rem;
    .field__item {
      display: block;
      width: 100%;
      background-color: var(--bs-gray-200);
      padding: 0.5rem 1rem;
      font-size: 1.5rem;
      text-align: center;
      font-weight: 500;
      color: $black;
      @include media-breakpoint-down(lg) {
        @include fullwidth();
      }
    }
  }

  // Sidebar Start here. The tag styling can be found in exploratorium/sidebar-tags/sidebar-tags.scss
  &__sidebar {
    .field--name-field-activity-type {
      @include media-breakpoint-down(lg) {
        display: none;
      }
    }
    .field--name-field-short-description-rte {
      margin-bottom: 2.6rem;
      padding: 1rem;
      border: 1px solid var(--bs-gray-300);
      background-color: $body-bg-level-2;
      display: block;
      @include media-breakpoint-down(lg) {
        background-color: transparent;
        display: inline-block;
        margin-bottom: 1rem;
      }
      p:first-child {
        margin: 0;
      }
    }
  }

  // Main content on right start here.
  &__content {
    margin-top: 1.875rem;

    .field--name-field-subtitle {
      @extend .h3;
      color: var(--bs-gray-500);
    }
    &__desktop-title {
      display: block;
      @include media-breakpoint-down(lg) {
        display: none;
      }
    }
  }

  .field--name-field-activity-section {
    & > * {
      margin-top: 1.87rem;
    }
  }
}
