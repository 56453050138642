.footer {
    margin-top: 20px;
    text-align: center;
}

.footer a {
    color: grey;
    text-decoration: none;
}
.footer a:hover {
    color: white;
    text-decoration: none;
}
