.featured-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
  gap: 1.8rem;
  padding-bottom: 2.75rem;
  border-bottom: solid 1px $white;
  margin-bottom: 1rem;
  @include media-breakpoint-down(lg) {
    flex-direction: column;
    padding-bottom: 1.18rem;
    gap: 1rem;
  }

  &__media-wrapper {
    flex: 1;
    .media-generic-image,
    .media-generic-image__figure {
      width: 100%;
    }
    @include media-breakpoint-down(lg) {
      width: 100%;
    }
  }

  &__content {
    flex: 1;
    * + * {
      margin-top: 0.5rem;
    }
  }

  &__title,
  &__subtitle {
    margin-bottom: 0;
  }

  &__description {
     > *:last-child {
      margin-bottom: 0;
    }
  }

  &__media-wrapper {
    background-color: $light;
    aspect-ratio: 16/9;
  }

  &__tag {
    display: inline-block;
    background-color: $secondary;
    font-size: 0.8rem;
    padding: 0.3rem 0.6rem;
    color: $white;
  }
  &__links {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.5rem;
    a {
      margin-top: 0;
    }
  }
}
