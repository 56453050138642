// Styles for search view, facets and form web/themes/custom/oppenheimer/templates/paragraphs/paragraph--site-search.html.twig

.search-component {


  // Search form
  .views-exposed-form {

    .form-item-search-api-fulltext {
      position: relative;


      input {
        padding-left: 2.4rem;
      }


      &:before {
        content: '';
        background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='white' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat 13px center;
        position: absolute;
        top: 50%;
        left: 0;
        width: 40px;
        height: 40px;
        transform: translateY(-50%);
      }
    }

    .form-actions {
      display: none;
    }
  }

  // Facets.
  &__filters {
    .offcanvas-body {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    ul {
      list-style-type: none;
      padding: 0;
      ul {
        padding-left: 0.8rem;
      }
      li {
        label {
          display: inline;
        }
      }
    }

    details {

      &:has(.block-facet--checkbox.hidden) {
        display: none;
      }

      legend {
        display: none;
      }

      .explo-details__content {
        max-height: 21.85rem;
        overflow-y: scroll;
      }
      .explo-details__content::-webkit-scrollbar {
        background-color: transparent;
      }
      .explo-details__content::-webkit-scrollbar-thumb {
        background-color: $dark;
        border-radius: 1.2rem;
      }
    }


    .facets-widget-checkbox {
      label {
        padding-left: 0.4rem;
        text-transform: capitalize;
      }
      input {
        @extend .form-check-input;
      }
    }
  }
}
