.form-select {
  max-width: 100%;
  // Chosen.
  &.chosen-container {
    max-width: 100%;

    &.chosen-container-active,
    &:focus {
      border-color: $form-select-focus-border-color;
      box-shadow: $form-select-focus-box-shadow;
    }


    .chosen-single,
    .chosen-choices {
      background: transparent;
      border: none;
      border-radius: 0;
      box-shadow: none;
      color: $body-color;
      // Hide ugly chosen arrow
      div {
        display: none;
      }
    }

    .chosen-choices {
      .search-choice {
        background-image: none;
        box-shadow: none;
        color: $white;
        padding: 0.8rem 1.4rem 0.8rem 0.8rem;
        background-color: $black;
        .search-choice-close {
          top: 25%;
          transform: translateY(-50%);
        }
      }
    }

    .chosen-drop {
      left: 0;
    }
  }
}
