// SASS variable overiders here.

// Bootstrap button sass.
@import "~bootstrap/scss/buttons";

$btn-padding-y: .938rem !default;
$btn-padding-x: 1.563rem !default;

// Stretch the button.
.btn {
  font-weight: 500;
  --bs-btn-border-width: 2px;
  --bs-btn-padding-y: .938rem;
  --bs-btn-padding-x: 1.563rem;

  &.btn-wide {
    --bs-btn-padding-x: 2.5rem;
  }

  &.btn-sm {
    --bs-btn-padding-y: 0.25rem;
    --bs-btn-padding-x: 0.5rem;
  }

  &--stretched-link {
    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      content: '';
    }
  }
  &-outline-primary {
    color: $primary;
  }
  &-outline-secondary {
    color: $secondary;
  }
  &-outline-success {
    color: $success;
  }
  &-outline-info {
    color: $info;
  }
  &-outline-warning {
    color: $warning;
  }
  &-outline-danger {
    color: $danger;
  }
  &-outline-dark {
    color: $dark;
  }
  &-primary, &-outline-primary {
    &:hover, &:focus {
      background-color: lighten($primary, 15%);
      border-color: lighten($primary, 15%);
      color: $black;
    }
  }
  &-secondary, &-outline-secondary {
    &:hover, &:focus {
      background-color: lighten($secondary, 15%);
      border-color: lighten($secondary, 15%);
      color: $black;
    }
  }
  &-success, &-outline-success {
    &:hover, &:focus {
      background-color: lighten($success, 15%);
      border-color: lighten($success, 15%);
      color: $black;
    }
  }
  &-info, &-outline-info {
    &:hover, &:focus {
      background-color: lighten($info, 15%);
      border-color: lighten($info, 15%);
      color: $black;
    }
  }
  &-warning, &-outline-warning {
    &:hover, &:focus {
      background-color: lighten($warning, 15%);
      border-color: lighten($warning, 15%);
      color: $black;
    }
  }
  &-danger, &-outline-danger {
    &:hover, &:focus {
      background-color: lighten($danger, 15%);
      border-color: lighten($danger, 15%);
      color: $black;
    }
  }
  &-dark, &-outline-dark {
    &:hover, &:focus {
      background-color: lighten($dark, 15%);
      border-color: lighten($dark, 15%);
      color: $black;
    }
  }
  &-white {
    @include button-variant(
      $background: $white,
      $border: $white,
      $color: $black,
      $hover-background: darken($white, 15%),
      $hover-border: darken($white, 15%),
      $hover-color: $black,
      $active-background: darken($white, 15%),
      $active-border: darken($white, 15%),
      $active-color: darken($black, 15%)
    );
  }
  &-outline-white {
    @include button-outline-variant(
      $white,
      $color-hover: $black,
      $active-background: $white,
      $active-border: $white,
      $active-color: $black
    )
  }
}
