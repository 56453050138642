.featued-image-container {
  &.full {
    .media-generic-image__figure {
      width: 100%;
    }
  }
}
.feature-image {
  img {
    width: 100%;
  }
}
