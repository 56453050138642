// Breadcrumb Variables and docs can be found here. https://getbootstrap.com/docs/5.0/components/breadcrumb/
$breadcrumb-font-size: 13px;
$breadcrumb-padding-y: 0;
$breadcrumb-padding-x: 0;
$breadcrumb-item-padding-x: 0;
$breadcrumb-margin-bottom: 0;
$breadcrumb-bg: null;
$breadcrumb-divider-color: $gray-600;
$breadcrumb-active-color: $gray-600;
$breadcrumb-inactive-color: $light-300;
$breadcrumb-border-radius: null;

// Bootstrap Breadcrumbs.
@import "~bootstrap/scss/breadcrumb";


#block-breadcrumbs {
  border-bottom: solid 1px $light;
  padding-top: 0.65rem;
  padding-bottom: 0.65rem;
  background-color: $black;

  @include media-breakpoint-down(lg) {
    display: none;
  }
}

.breadcrumb-container {
  .breadcrumb {
    --#{$prefix}breadcrumb-padding-x: #{$breadcrumb-padding-x};
    --#{$prefix}breadcrumb-padding-y: #{$breadcrumb-padding-y};
    --#{$prefix}breadcrumb-margin-bottom: #{$breadcrumb-margin-bottom};
    @include rfs($breadcrumb-font-size, --#{$prefix}breadcrumb-font-size);
    --#{$prefix}breadcrumb-bg: #{$breadcrumb-bg};
    --#{$prefix}breadcrumb-border-radius: #{$breadcrumb-border-radius};
    --#{$prefix}breadcrumb-divider-color: #{$breadcrumb-divider-color};
    --#{$prefix}breadcrumb-item-padding-x: #{$breadcrumb-item-padding-x};
    --#{$prefix}breadcrumb-item-active-color: #{$breadcrumb-active-color};
    &-item {
      position: relative;
      &:before {
        display: none;
      }
      a {
        position: relative;
        color: $breadcrumb-inactive-color;
        text-decoration: none;
        font-weight: 300;
        &:hover, &:focus {
          color: $white;
          &:before {
            display: none;
          }
        }
      }
      &.active {
        color: $white;
      }
      span {
        &:before {
          display: none;
        }
      }
    }
    &-item-chevron {
      width: 10px;
      height: 10px;
      margin: 0 10px;
      fill: $light;
    }
  }
}
