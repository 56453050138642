.mobile-menu-region {

  .offcanvas-header {
    margin: 0;
    padding: 1rem;
    background-color: $black;
    border-bottom: none;
    &__divider {
      display: none;
      width: 1px;
      height: 100%;
      background-color: $light-500;
    }
    &:has(.language-swicher) {
      .offcanvas-header__divider {
        display: block;
      }
    }
  }

  &__search-link {
    border: none;
    background: transparent;
    color: inherit;
  }

  .offcanvas-body {
    overflow-x: hidden;
    padding-top: 0;
  }

  nav {
    position: relative;

    // Reset ul and li
    ul {
      padding: 0;
      list-style: none;
      margin: 0;
      li {
        list-style: none;
        &:last-of-type {
          .menu-item__link-wrapper {
            &:before {
              border-bottom: none;
            }
          }
        }
      }
      .menu-item__link {
        text-decoration: none;
        color: $light-100;
      }
    }

    .menu-item {
      &:hover,
      &:focus {
        cursor: pointer;
      }
      &__link-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        width: 100%;
        padding: 0 0.5rem;
        font-weight: 500;

        &:before {
          content: '';
          position: absolute;
          background-color: transparent;
          height: 100%;
          width: calc(100% + 2rem);
          top: 50%;
          left: -1rem;
          transform: translateY(-50%);
          border-bottom: solid 1px $light;
          transition: 0.4s;
        }

        &--is-active,
        &:hover,
        &:focus {
          &:before {
            background-color: $light;
          }
        }

        &--back-to {
          flex-direction: row-reverse !important;
          &.menu-item__link-wrapper--is-active {
            &:before {
              visibility: hidden;
              opacity: 0;
            }
          }
          &:hover,
          &:focus {
            visibility: visible;
            opacity: 1;
          }
          .menu-item__link {
            margin-left: 0.5rem;
            color: $light-500;
          }
          .menu-item__expand-icon {
            transform: rotate(180deg);
          }
        }
      }

      &__link {
        position: relative;
        flex: 1;
        padding: 1rem 0;
      }
      &__expand-icon {
        position: relative;
      }
    }

    .menu--child {
      position: absolute;
      width: calc(100% + 2rem);
      padding: 0 1rem;
      height: auto;
      min-height: 100vh !important;
      max-height: 100%;
      overflow-x: hidden;
      overflow-y: scroll;
      top: 0;
      left: -1rem;
      z-index: 1000;
      background: inherit;
      list-style: none;
      background: var(--bs-offcanvas-bg);
      transform: translateX(120%);
      opacity: 0;
      visibility: hidden;
      transition: 0.4s;

      scrollbar-width: thin;
      scrollbar-color: transparent transparent;

      &::-webkit-scrollbar {
        width: 1px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: transparent;
      }

      &.show {
        transform: translateX(0) !important;
        opacity: 1 !important;
        visibility: visible !important;
      }
    }
  }

  #block-explo-mobile-secondary-links {
    .config_pages--type--explo-header-secondary-link {
      .field__item {
        width: 100%;
        a {
          width: 100%;
        }
      }
    }
  }
}
