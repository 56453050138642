// Card Variables.
$card-spacer-y: $spacer;
$card-spacer-x: $spacer;
$card-title-spacer-y: $spacer * 0.5;
$card-border-width: 0;
$card-border-radius: 0;
$card-border-color: 0;
$card-inner-border-radius: subtract($card-border-radius, $card-border-width);
$card-cap-padding-y: $card-spacer-y * 0.5;
$card-cap-padding-x: $card-spacer-x;
$card-cap-bg: rgba($black, 0.03);
$card-cap-color: null;
$card-height: null;
$card-color: $white;
$card-bg: $dark-600;
$card-img-overlay-padding: $spacer;
$card-group-margin: $grid-gutter-width * 0.5;

// Bootstrap SASS CARD.
@import "~bootstrap/scss/card";

.card {
  --#{$prefix}card-spacer-y: #{$card-spacer-y};
  --#{$prefix}card-spacer-x: #{$card-spacer-x};
  --#{$prefix}card-title-spacer-y: #{$card-title-spacer-y};
  --#{$prefix}card-border-width: #{$card-border-width};
  --#{$prefix}card-border-color: #{$card-border-color};
  --#{$prefix}card-border-radius: #{$card-border-radius};
  --#{$prefix}card-box-shadow: #{$card-box-shadow};
  --#{$prefix}card-inner-border-radius: #{$card-inner-border-radius};
  --#{$prefix}card-cap-padding-y: #{$card-cap-padding-y};
  --#{$prefix}card-cap-padding-x: #{$card-cap-padding-x};
  --#{$prefix}card-cap-bg: #{$card-cap-bg};
  --#{$prefix}card-cap-color: #{$card-cap-color};
  --#{$prefix}card-height: #{$card-height};
  --#{$prefix}card-color: #{$card-color};
  --#{$prefix}card-bg: #{$card-bg};
  --#{$prefix}card-img-overlay-padding: #{$card-img-overlay-padding};
  --#{$prefix}card-group-margin: #{$card-group-margin};
  img {
    max-width: 100%;
    &.lazy-load-image {
      opacity: 0;
      transition: opacity 0.4;
      &[data-loaded="true"] {
        opacity: 1;
      }
    }
    &.card-image-object-contain {
      object-fit: contain;
    }
    &.card-image-object-cover {
      object-fit: cover;
    }
    &.card-image-object-cover {
      object-fit: fill;
    }
  }
  .recurring-output--wrapper {
    ul {
      list-style: none;
      padding: 0;
    }
  }
  // Whole card clickable.
  &.card-clickable {
    transition: transform .2s;
    &:hover,
    &:focus {
      transform: scale(1.05);
    }
  }
}

.card-title {
  font-size: 1.6875rem;
  font-weight: normal;
}

.card-subtitle {
  font-weight: normal;
}

// Horizontal Cards Shared Styles.
.card-horizontal-clickable,
.card-horizontal {
  .card-img-top,
  .card-horizontal-clickable__image-wrapper {
    img {
      height: 100%;
      object-fit: cover;
      &.card-image-object-contain {
        object-fit: contain;
      }
    }
  }
}

// Horizontal Card Clickable
.card-horizontal-clickable {
  transform: translateY(0);
  transition: 0.4s;
  &:hover, &:focus {
    transform: translateY(-5px);
  }
}

// Horizontal Card.
.card-horizontal {
  &__body {
    display: flex;
    flex-direction: column;
  }
  &__links-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
    margin-top: auto;
  }
}
