
body {
  &.mobile-menu-active {
    height: 100vh;
    overflow: hidden;
  }
  // page is a media.
  &.page-media {
    #main-content {
      @extend .container;
    }
  }
}
.dialog-off-canvas-main-canvas {
  position: relative;
}
.main-content {
  position: relative;
  right: 0;
  transition: 0.3s ease-in-out;
}
.main-content-container {
  &--side-bar-left-right {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 'side-left content side-right';
    grid-template-columns: 260px 1fr 260px;
    @include media-breakpoint-down(md) {
      grid-template-columns: 1fr;
    }
  }
  &--has-side-bar-left {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 'side-left content';
    grid-template-columns: 260px 1fr;
    @include media-breakpoint-down(md) {
      grid-template-columns: 1fr;
    }
  }
  &--has-side-bar-right {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 'content side-right';
    grid-template-columns: 1fr 260px;
    @include media-breakpoint-down(md) {
      grid-template-columns: 1fr;
    }
  }
}
