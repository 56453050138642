#searchModal {
  .container {
    @include media-breakpoint-down(lg) {
      padding: 0;
    }
  }
  .modal-dialog {
    min-width: 100%;
    width: 100%;
    background-color: $body-bg-level-3;
    @include media-breakpoint-down(lg) {
      margin-left: 0;
    }
  }
  // Close Button
  .btn-close {
    position: absolute;
    top: 5px;
    right: 13px;
    color: white;
    opacity: 1;
    filter: invert(100%);
  }
  .modal-header {
    padding-top: 2.8rem;
    border: none;
  }
  .modal-body {
    padding-top: 2.8rem;
    padding-bottom: 2.5rem;
  }
}
