.experience-detail-page {

  // Experience Type.
  .field--name-field-experience-type {
    display: block;
    width: 100%;
    background-color: var(--bs-gray-200);
    padding: 0.5rem 1rem;
    font-size: 1.5rem;
    text-align: center;
    font-weight: 500;
    color: $black;
    @include media-breakpoint-down(lg) {
      @include fullwidth();
    }
  }

  // title Icon helper class
  &__title-icon {
    font-weight: 400;
    color: var(--bs-gray-500);
    font-size: 1.25rem;
  }

  // Experience Date
  &__date {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-weight: 400;
    gap: 0.5rem;

    .field--name-field-experience-date {
      display: flex;
      flex-direction: column;
      font-size: 1.5rem;
      line-height: 2.5rem;
      color: var(--bs-gray-100);
    }
  }

  // Price
  &__price-wrapper {
    .bi-ticket-fill {
      &:before {
        transform: rotate(90deg);
      }
    }
    &__price {
      font-size: 1.5rem;
      line-height: 2.5rem;
      color: var(--bs-gray-100);
    }
  }

  // Location
  &__location-wrapper {
    .field__item {
      text-decoration: none;
      font-size: 1.5rem;
      line-height: 2.5rem;
      color: var(--bs-gray-100);
    }
    a {
      text-decoration: none;
      font-size: 1.5rem;
      line-height: 2.5rem;
      color: var(--bs-gray-100);
      &:hover,
      &:focus {
        text-decoration: underline;
        color: var(--bs-gray-500);
      }
    }
  }

  // Creator
  &__creator {
    .field--name-field-creator-title {
      font-size: 1.5rem;
      line-height: 2.5rem;
      color: var(--bs-gray-100);
    }
  }

  .field--name-field-sidebar-text {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .field__item {
      padding: 1rem;
      border: 1px solid var(--bs-gray-300);
      background-color: $dark;
      display: block;
      p:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__sidebar {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    align-self: flex-start;
    @include media-breakpoint-down(lg) {
      position: relative;
    }
    &__title {
      display: none;
      @include media-breakpoint-down(lg) {
        display: block;
      }
    }
    .sidebar-tags-accordion {
      margin-top: 0;
    }
  }

  &__content {
    .main-content-title {
      display: block;
      @include media-breakpoint-down(lg) {
        display: none;
      }
    }
  }
}
