.explo-header-nav {
  --explo-header-nav-link-color: #{$light-300};
  --explo-header-nav-link-color-hover: var(--bs-white);
  --explo-header-nav-link-active-border-color: #{$blue-500};
  --explo-header-nav-nested-bg-color: #{$body-bg-level-1};
  --explo-header-nav-nested-bg-color-hover: #{$light-600};
  --explo-header-nav-nested-menu-item-border: #{$light};
  --explo-header-nav-nested-link-color: #{$light-300};
  // Reset ul and li
  ul {
    padding: 0;
    list-style: none;
    margin: 0;
    li {
      list-style: none;
    }
    .menu-item__link {
      display: block;
      text-decoration: none;
      color: var(--explo-header-nav-link-color);
    }
  }

  .menu--parent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;


    .menu-item {
      position: relative;
      margin-right: 1.3rem;
      border-bottom: solid 4px transparent;
      font-weight: 500;
      transition: 0.4s;
      @include media-breakpoint-down(xl) {
        margin-right: 0.5rem;
        font-size: 0.9rem
      }

      // Helper li wrapper classes
      &.line-left-desktop-only {
        margin-left: 2.5rem;
        &:before {
          content: '';
          position: absolute;
          top: 50%;
          left: -1.8rem;
          width: 1px;
          height: 50%;
          background-color: $light;
          transform: translateY(-50%);
        }
      }

      .menu-item__link {
        &:first-of-type {
          padding: 1.6rem .5rem;
          white-space: pre;

        }
      }

      // Active trail styles.
      &.menu-item--active-trail {
        border-bottom-color: var(--explo-header-nav-link-active-border-color);
        .menu-item__link-wrapper {
          color: $white;
          .menu-item__link {
            color: $white;
          }
        }
      }

      &.show-children {
        .menu--child {
          opacity: 1;
          visibility: visible;
          transform: translateY(0%);
        }
      }

      // Focus Hover Styles.
      &.show-children,
      &:focus,
      &:hover,
      &:focus-visible {
        cursor: pointer;
        border-bottom-color: var(--explo-header-nav-link-active-border-color);

        .menu-item__link-wrapper {
          color: var(--explo-header-nav-link-color-hover);
          .menu-item__link {
            color: var(--explo-header-nav-link-color-hover);
          }
        }
      }

      &:last-of-type {
        margin-right: 0;
      }

      &__expand-icon {
        display: none;
      }
    }

    // Menu child wrapper.
    .menu--child {
      visibility: hidden;
      opacity: 0;
      min-width: 10rem;
      position: absolute;
      top: calc(100% + 5px);
      left: 0;
      transform: translateY(5%);
      background-color: var(--explo-header-nav-nested-bg-color);
      transition: 0.2s;
      max-width: 20.5rem;
      min-width: 15.5rem;
      z-index: 1000;

      // Nested menu item.
      .menu-item {
        padding: 0;
        margin-right: 0;
        background-color: transparent;
        border-bottom: solid 1px var(--explo-header-nav-nested-menu-item-border);
        transition: 0.4s;

        &:last-of-type {
          border-bottom: none;
        }

        .menu-item__link-wrapper {
          overflow: hidden;
          margin: 0;
          color: var(--explo-header-nav-nested-link-color);
          .menu-item__link {
            padding: 1rem;
            color: var(--explo-header-nav-nested-link-color);
            white-space: normal;
          }
        }

        // Hover focus an active.
        &.menu-item--active-trail,
        &:focus,
        &:hover,
        &:focus-within {
          background-color: var(--explo-header-nav-nested-bg-color-hover);
          .menu-item__link-wrapper {
            color: var(--explo-header-nav-link-color-hover);
            .menu-item__link {
              color: var(--explo-header-nav-link-color-hover);
            }
          }
        }
      }
    }
  }

}

