// Sass variable overrides here.
$popover-font-size: $font-size-sm;
$popover-bg: $white;
$popover-max-width: 276px;
$popover-border-width: $border-width;
$popover-border-color: rgba($black, 0.2);
$popover-border-radius: $border-radius-lg;
$popover-inner-border-radius: subtract(
  $popover-border-radius,
  $popover-border-width
);
$popover-box-shadow: $box-shadow;

$popover-header-bg: shade-color($popover-bg, 6%);
$popover-header-color: $headings-color;
$popover-header-padding-y: 0.5rem;
$popover-header-padding-x: $spacer;

$popover-body-color: $body-color;
$popover-body-padding-y: $spacer;
$popover-body-padding-x: $spacer;

$popover-arrow-width: 1rem;
$popover-arrow-height: 0.5rem;
$popover-arrow-color: $popover-bg;

$popover-arrow-outer-color: fade-in($popover-border-color, 0.05);

// Bootstrap popovers.
@import "~bootstrap/scss/popover";

.popover {
  --#{$prefix}popover-zindex: #{$zindex-popover};
  --#{$prefix}popover-max-width: #{$popover-max-width};
  @include rfs($popover-font-size, --#{$prefix}popover-font-size);
  --#{$prefix}popover-bg: #{$popover-bg};
  --#{$prefix}popover-border-width: #{$popover-border-width};
  --#{$prefix}popover-border-color: #{$popover-border-color};
  --#{$prefix}popover-border-radius: #{$popover-border-radius};
  --#{$prefix}popover-inner-border-radius: #{$popover-inner-border-radius};
  --#{$prefix}popover-box-shadow: #{$popover-box-shadow};
  --#{$prefix}popover-header-padding-x: #{$popover-header-padding-x};
  --#{$prefix}popover-header-padding-y: #{$popover-header-padding-y};
  @include rfs($popover-header-font-size, --#{$prefix}popover-header-font-size);
  --#{$prefix}popover-header-color: #{$popover-header-color};
  --#{$prefix}popover-header-bg: #{$popover-header-bg};
  --#{$prefix}popover-body-padding-x: #{$popover-body-padding-x};
  --#{$prefix}popover-body-padding-y: #{$popover-body-padding-y};
  --#{$prefix}popover-body-color: #{$popover-body-color};
  --#{$prefix}popover-arrow-width: #{$popover-arrow-width};
  --#{$prefix}popover-arrow-height: #{$popover-arrow-height};
  --#{$prefix}popover-arrow-border: var(--#{$prefix}popover-border-color);
}
