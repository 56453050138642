.media-carousel-thumbnail {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  z-index: 5;
  @include media-breakpoint-down(md) {
    justify-content: center;
  }

  &__image-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    max-width: 180px;
    background-color: $black;

    @include media-breakpoint-down(850) {
      max-width: 150px;
      max-height: 100px;
    }

    // Inset boarder on images.
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      box-shadow: inset 0px 0px 0px 1px rgba($white,1);
      transition: 0.2s;
    }

    img {
      max-width: 100%;
      max-height: 100px;
    }

    svg {
      position: absolute;
      top: 50%;
      right: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 3.25rem;
      z-index: 2;
    }
  }

  &__title {
    flex: 1;
    margin-left: .8rem;
    font-size: 0.9rem;
    max-width: 165px;
    // @include media-breakpoint-down(400px) {
    //   display: none;
    // }
  }

  &.swiper-slide-thumb-active {
    .media-carousel-thumbnail__image-wrapper {
      &:after {
        box-shadow: inset 0px 0px 0px 4px rgba($white,1);
      }
    }
  }

  &:hover,
  &:focus {
    cursor: pointer;
    .media-carousel-thumbnail__image-wrapper {
      &:after {
        box-shadow: inset 0px 0px 0px 4px rgba($white,1);
      }
    }
  }

}
