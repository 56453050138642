// Theme colors.
$primary: #2D75B1;
$secondary: #6A5FAC;
$success: #3A825E;
$danger: #B25364;
$warning: #9B8742;
$info: #4B7D75;
$light: #565656;
$dark: #2E2E2E;
$white: #FFFFFF;
$black: #000000;

// Colors
$blue:    #3892DD;
$indigo:  #8477D7;
$purple:  #A777D7;
$pink:    #DC77C0;
$red:     #DE687D;
$orange:  #DE9268;
$yellow:  #C2A953;
$green:   #49A276;
$teal:    #5E9C92;
$cyan:    #6B9AB5;

// Blues
$blue-400: #60A8E4;

// Light
$light-100: #E2E2E2; // Text Emphasis used on headings.
$light-200: #C4C4C4;
$light-300: #A7A7A7; // Text Regular
$light-400: #898989;
$light-500: #6C6C6C;
$light-600: $light;
$light-700: #414141;
$light-800: #2B2B2B;
$light-900: #161616;

$lights: (
  "light":     $light,
  "light-100": $light-100, // Text Emphasis used on headings.
  "light-200": $light-200,
  "light-300": $light-300,
  "light-400": $light-400,
  "light-500": $light-500,
  "light-600": $light-600,
  "light-700": $light-700,
  "light-800": $light-800,
  "light-900": $light-900
);

// Gray
$gray-100: $light-100;
$gray-200: $light-200;
$gray-300: $light-300;
$gray-400: $light-400;
$gray-500: $light-500;
$gray-600: $light-600;
$gray-700: $light-700;
$gray-800: $light-800;
$gray-900: $light-900;

$grays: (
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900
);

// Dark
$dark-100: #585858;
$dark-200: #4D4D4D;
$dark-300: #434343;
$dark-400: #383838;
$dark-500: $dark;
$dark-600: #252525;
$dark-700: #1C1C1C;
$dark-800: #121212;
$dark-900: #090909;

$darks: (
  "dark":     $dark,
  "dark-100": $dark-100,
  "dark-200": $dark-200,
  "dark-300": $dark-300,
  "dark-400": $dark-400,
  "dark-500": $dark-500,
  "dark-600": $dark-600,
  "dark-700": $dark-700,
  "dark-800": $dark-800,
  "dark-900": $dark-900
);

// Body Background color.
$body-bg: $dark-800;
$body-bg-level-1: $dark-600;
$body-bg-level-2: $dark-500;
$body-bg-level-3: $dark-400;
$body-bg-light: $light-200;

$body-bgs: (
  "body-bg": $body-bg,
  "body-bg-level-1": $body-bg-level-1,
  "body-bg-level-2": $body-bg-level-2,
  "body-bg-level-3": $body-bg-level-3,
  "body-bg-light": $body-bg-light
);

// Links - Style anchor elements.

$link-color: $white !default;

// Font Colors.

$headings-color: #E7E7E7; // Headings font color.
$body-color: #B8B8B8; // Default font color.
$text-muted: rgba(255,255,255,0.5); // Muted font color.
