.footer-social-links {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 180px;
  &__icon {
    width: 16px;
    height: 16px;
    font-size: 18px;
    fill: var(--bs-gray-500);
    color: var(--bs-gray-500);
    &:hover,
    &:focus {
      fill: var(--bs-gray-600);
      color: var(--bs-gray-600);
    }
  }
}
