.site-search {
  &__icon {
    width: 16px;
    height: 16px;
  }
  &__submit {
    padding: 0;
    &:hover,
    &:active {
      color: var(--bs-white);
      background-color: transparent;
    }
  }
}
