.explo-header-top {
  --explo-ht-link-color: #{$light-300};
  --explo-ht-link-color-hover: var(--bs-white);
  --explo-ht-bg-color: #{$black};
  --explo-ht-mobile-menu-color: #{$white};
  background-color: var(--explo-ht-bg-color);
  border-bottom: solid 1px $light;
  &__container {
    padding-top: 1rem;
    padding-bottom: 1rem;
    @include media-breakpoint-down(lg) {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }
  }
  .mobile-menu-button {
    display: none;
    background: none;
    border: none;
    padding: 0;
    width: 2.8rem;
    @include media-breakpoint-down(lg) {
      display: block;
    }
    svg {
      fill: var(--explo-ht-mobile-menu-color);
      width: 100%;
      height: 100%;
    }
  }

  &__search-link {
    border: none;
    background: transparent;
    text-decoration: none;
    color: var(--explo-ht-link-color);
    .bi {
      margin-right: 0.2rem;
    }

    &:focus,
    &:hover {
      color: var(--explo-ht-link-color-hover);
    }
  }
}
