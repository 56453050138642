.audio-slideshow {
  &.audio-player-playing {
    .carousel-control-next,
    .carousel-control-prev {
      display: none;
    }

    .audio-player-captions {
      display: grid;
    }
  }

  .audio-controls {
    flex-wrap: wrap-reverse;
  }

  .audio-control {
    cursor: pointer;

    .audio-control-volume {
      position: relative;
    }
  }

  .audio-progress {
    background-color: $body-bg-level-3;

    .progress {
      background-color: inherit !important;
      height: calc($progress-border-radius * 2);
    }

    .audio-progress-stats {
      background-color: $card-bg;
    }
  }
}

