// Sass variable overrides here.
$carousel-indicator-width: 0.7rem;
$carousel-indicator-height: 0.7rem;
$carousel-control-icon-width: 1.5rem;

// Bootstrap Carousel.
@import "~bootstrap/scss/carousel";

.carousel-control-prev,
.carousel-control-next {
  z-index: 4;
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
  position: relative;
  &:before {
    content: '';
    background: $black;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    z-index: -1;
    border-radius: 100%;
    padding: 1.2rem;
  }
}

.carousel-control-prev-icon {
  &:before {
    transform: translate(-48%, -50%);
  }
}

.carousel-indicators [data-bs-target] {
  border-radius: 100%;
}
