.btn-xs {
    --bs-btn-padding-y: 0;
    --bs-btn-padding-x: 0;
}

.hitsList {
    padding: 0;
    margin: 0;
}
.hitsItem {
    list-style-type: none;
}

.refinementLabel {
    cursor: pointer;
}