.column-count-2,
.column-count-3 {
  column-gap: 40px;
}

.column-count-1 {
  column-count: 1;
}
.column-count-2 {
  @media screen and (min-width: 41em){
    column-count: 2;
  }
}
.column-count-3 {
  @media screen and (min-width: 41em){
    column-count: 3;
  }
}
