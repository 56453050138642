.js-form-type-radio {
  input {
    + label {
      cursor: pointer;
    }
    &:checked + label {
      color: var(--bs-white);
    }

    &:hover,
    &:focus {
      + label {
        color: var(--bs-white);
      }
      background-color: $component-active-bg;
      background-image: url("data:image/svg+xml,%3Csvg width='1024px' height='1024px' viewBox='0 0 1024 1024' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M904 476H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8z' fill='white'/%3E%3C/svg%3E");
    }
  }
  label {
    padding-left: 0.4rem;
    text-transform: capitalize;
    display: inline;
  }
}
