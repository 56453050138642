$dropdown-min-width:                10rem;
$dropdown-padding-x:                0;
$dropdown-padding-y:                .5rem;
$dropdown-spacer:                   .125rem;
$dropdown-font-size:                $font-size-base;
$dropdown-color:                    $white;
$dropdown-bg:                        $dark;
$dropdown-border-color:             var(--#{$prefix}border-color-translucent);
$dropdown-border-radius:            $border-radius;
$dropdown-border-width:             $border-width;
$dropdown-inner-border-radius:      subtract($dropdown-border-radius, $dropdown-border-width);
$dropdown-divider-bg:               $dropdown-border-color;
$dropdown-divider-margin-y:         $spacer * .5;
$dropdown-box-shadow:               $box-shadow;

$dropdown-link-color:               $light-100;
$dropdown-link-hover-color:         shade-color($dropdown-link-color, 10%);
$dropdown-link-hover-bg:            $blue-700;

$dropdown-link-active-color:        $white;
$dropdown-link-active-bg:           $blue-700;

$dropdown-link-disabled-color:      $gray-500;

$dropdown-item-padding-y:           $spacer * .25;
$dropdown-item-padding-x:           $spacer;

$dropdown-header-color:             $gray-600;
$dropdown-header-padding-x:         $dropdown-item-padding-x;
$dropdown-header-padding-y:         $dropdown-padding-y;
// fusv-disable
$dropdown-header-padding:           $dropdown-header-padding-y $dropdown-header-padding-x; // Deprecated in v5.2.0
// fusv-enable

.dropdown {
  --#{$prefix}dropdown-zindex: #{$zindex-dropdown};
  --#{$prefix}dropdown-min-width: #{$dropdown-min-width};
  --#{$prefix}dropdown-padding-x: #{$dropdown-padding-x};
  --#{$prefix}dropdown-padding-y: #{$dropdown-padding-y};
  --#{$prefix}dropdown-spacer: #{$dropdown-spacer};
  @include rfs($dropdown-font-size, --#{$prefix}dropdown-font-size);
  --#{$prefix}dropdown-color: #{$dropdown-color};
  --#{$prefix}dropdown-bg: #{$dropdown-bg};
  --#{$prefix}dropdown-border-color: #{$dropdown-border-color};
  --#{$prefix}dropdown-border-radius: #{$dropdown-border-radius};
  --#{$prefix}dropdown-border-width: #{$dropdown-border-width};
  --#{$prefix}dropdown-inner-border-radius: #{$dropdown-inner-border-radius};
  --#{$prefix}dropdown-divider-bg: #{$dropdown-divider-bg};
  --#{$prefix}dropdown-divider-margin-y: #{$dropdown-divider-margin-y};
  --#{$prefix}dropdown-box-shadow: #{$dropdown-box-shadow};
  --#{$prefix}dropdown-link-color: #{$dropdown-link-color};
  --#{$prefix}dropdown-link-hover-color: #{$dropdown-link-hover-color};
  --#{$prefix}dropdown-link-hover-bg: #{$dropdown-link-hover-bg};
  --#{$prefix}dropdown-link-active-color: #{$dropdown-link-active-color};
  --#{$prefix}dropdown-link-active-bg: #{$dropdown-link-active-bg};
  --#{$prefix}dropdown-link-disabled-color: #{$dropdown-link-disabled-color};
  --#{$prefix}dropdown-item-padding-x: #{$dropdown-item-padding-x};
  --#{$prefix}dropdown-item-padding-y: #{$dropdown-item-padding-y};
  --#{$prefix}dropdown-header-color: #{$dropdown-header-color};
  --#{$prefix}dropdown-header-padding-x: #{$dropdown-header-padding-x};
  --#{$prefix}dropdown-header-padding-y: #{$dropdown-header-padding-y};
}
