.media-full-content-display {

  &__info-bar {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    border-bottom: solid var(--bs-gray-500) 1px;
    padding-bottom: 0.62rem;
  }

  &__addtoany {
    a {
      svg {
        fill: var(--bs-gray-300);
        transition: 0.2s;
      }
      &:hover,
      &:focus {
        svg {
          fill: var(--bs-gray-600);
        }
      }

      // addtoany share universal btn.
      &.addtoany_share {
        .a2a_svg {
          background-color: var(--bs-gray-300) !important;
          border-radius: 100% !important;
          transition: 0.2s;
        }
        svg {
          g {
            fill: var(--bs-body-bg);
          }
        }
        &:hover,
        &:focus {
          .a2a_svg {
            background-color: var(--bs-gray-600) !important;
          }
        }
      }
    }
  }
}
