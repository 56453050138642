.four-articles-micro-site-grid {
  &__feature {
    @include media-breakpoint-down(lg) {
      margin-bottom: 1rem;
    }
    .simple-teaser {
      &__image-wrapper {
        // When its a carousel
        .feature-image--carousel {
          .carousel-control-next,
          .carousel-control-prev {
            z-index: 2;
          }
        }
      }
    }
  }
  &__secondary {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 2rem;
    @include media-breakpoint-down(lg) {
      gap: 0;
    }
    .simple-teaser {
      position: relative;
      display: flex;
      flex-direction: row;
      gap: 1rem;
      @include media-breakpoint-down(lg) {
        padding: 1rem 0;
        border-top: solid 1px $white;
      }

      &__image-wrapper {
        display: block;
        margin-bottom: 0 !important;
        max-width: 170px;
        @include media-breakpoint-down(md) {
          max-width: 150px;
        }
        .media-generic-image__figure {
          margin: 0;
        }
        img {
          height: auto;
          max-width: 170px;
          width: 170px;
          object-fit: cover;
          @include media-breakpoint-down(md) {
            width: 150px;
          }
        }
        svg {
          width: 170px;
          @include media-breakpoint-down(md) {
            width: 150px;
          }
        }

        // When its a carousel
        .feature-image--carousel {
          .carousel-control-next,
          .carousel-control-prev {
            z-index: 2;
          }
          .carousel-control-next-icon,
          .carousel-control-prev-icon {
            width: 0.8rem;
            height: 0.8rem;
            &:before {
              content: '';
              padding: 0.5rem;
            }
          }
        }
      }
      &__link {
        @extend .h6;
      }
    }
  }


}
