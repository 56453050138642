// Sass variable overrides here.
$pagination-padding-y: 15px;
$pagination-padding-x: 25px;
$pagination-padding-y-sm: 15px;
$pagination-padding-x-sm: 25px;
$pagination-padding-y-lg: 15px;
$pagination-padding-x-lg: 25px;

$pagination-color: $white;
$pagination-bg: transparent;
$pagination-border-width: 1px;
$pagination-border-radius: 10px;
$pagination-margin-start: -$pagination-border-width;
$pagination-border-color: transparent;

$pagination-focus-color: $black;
$pagination-focus-bg: $white;
$pagination-focus-box-shadow: $input-btn-focus-box-shadow;
$pagination-focus-outline: 0;

$pagination-hover-color: $black;
$pagination-hover-bg: $white;
$pagination-hover-border-color: $white;

$pagination-active-color: $black;
$pagination-active-bg: $white;
$pagination-active-border-color: $white;

$pagination-disabled-color: $gray-600;
$pagination-disabled-bg: $white;
$pagination-disabled-border-color: $gray-300;

$pagination-transition: color 0.15s ease-in-out,
  background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
  box-shadow 0.15s ease-in-out;

$pagination-border-radius-sm: $border-radius-sm;
$pagination-border-radius-lg: $border-radius-lg;

// Bootstrap Pagination
@import "~bootstrap/scss/pagination";


.pagination {
  --#{$prefix}pagination-padding-x: #{$pagination-padding-x};
  --#{$prefix}pagination-padding-y: #{$pagination-padding-y};
  @include rfs($pagination-font-size, --#{$prefix}pagination-font-size);
  --#{$prefix}pagination-color: #{$pagination-color};
  --#{$prefix}pagination-bg: #{$pagination-bg};
  --#{$prefix}pagination-border-width: #{$pagination-border-width};
  --#{$prefix}pagination-border-color: #{$pagination-border-color};
  --#{$prefix}pagination-border-radius: #{$pagination-border-radius};
  --#{$prefix}pagination-hover-color: #{$pagination-hover-color};
  --#{$prefix}pagination-hover-bg: #{$pagination-hover-bg};
  --#{$prefix}pagination-hover-border-color: #{$pagination-hover-border-color};
  --#{$prefix}pagination-focus-color: #{$pagination-focus-color};
  --#{$prefix}pagination-focus-bg: #{$pagination-focus-bg};
  --#{$prefix}pagination-focus-box-shadow: #{$pagination-focus-box-shadow};
  --#{$prefix}pagination-active-color: #{$pagination-active-color};
  --#{$prefix}pagination-active-bg: #{$pagination-active-bg};
  --#{$prefix}pagination-active-border-color: #{$pagination-active-border-color};
  --#{$prefix}pagination-disabled-color: #{$pagination-disabled-color};
  --#{$prefix}pagination-disabled-bg: #{$pagination-disabled-bg};
  --#{$prefix}pagination-disabled-border-color: #{$pagination-disabled-border-color};

  &__items {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    @include media-breakpoint-down(md) {
      justify-content: center;
      gap: 0.8rem;
    }
  }

  &__link {
    border-radius: $pagination-border-radius;
  }

  .page-item-number,
  .pagination__item--ellipsis {
    @include media-breakpoint-down(md) {
      display: none !important;
    }
  }

  .pagination__item--previous,
  .pagination__item--next {
    @include media-breakpoint-down(md) {
      span {
        display: none;
      }
    }
  }
}
