.masonry-collection {
  &__grid {
    opacity: 0;
    transition: 0.4s ease-in;
    &.loaded {
      opacity: 1;
    }
  }
  &__item {
    width: 50%;
    padding: 5px;
    box-sizing: border-box;
    display:inline;

    .media-generic-image {
      display: block;
      .media-generic-image__figure {
        display: block;
      }
      img {
        max-width: 100%;
        width: 100%;
      }
      .media-generic-image__caption {
        display: block;
      }
    }

    @media (min-width: 1000px) {
      width: 33.333%;
    }

    @media (min-width: 1700px) {
      width: 25%;
    }
    @media (min-width: 2100px) {
      width: 20%;
    }
  }
}
