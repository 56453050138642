// Sass variable overrides here.
// Offcanvas Variables and docs can be found here. https://getbootstrap.com/docs/5.2/components/offcanvas/#variables
$offcanvas-padding-y: $modal-inner-padding;
$offcanvas-padding-x: $modal-inner-padding;
$offcanvas-horizontal-width: 400px;
$offcanvas-vertical-height: 30vh;
$offcanvas-transition-duration: 0.3s;
$offcanvas-border-color: $modal-content-border-color;
$offcanvas-border-width: $modal-content-border-width;
$offcanvas-title-line-height: $modal-title-line-height;
$offcanvas-bg-color: $body-bg-level-1;
$offcanvas-color: $modal-content-color;
$offcanvas-box-shadow: $modal-content-box-shadow-xs;

// Bootstrap offcanvas.
@import "~bootstrap/scss/offcanvas";

.offcanvas {
  --#{$prefix}offcanvas-width: #{$offcanvas-horizontal-width};
  --#{$prefix}offcanvas-height: #{$offcanvas-vertical-height};
  --#{$prefix}offcanvas-padding-x: #{$offcanvas-padding-x};
  --#{$prefix}offcanvas-padding-y: #{$offcanvas-padding-y};
  --#{$prefix}offcanvas-color: #{$offcanvas-color};
  --#{$prefix}offcanvas-bg: #{$offcanvas-bg-color};
  --#{$prefix}offcanvas-border-width: #{$offcanvas-border-width};
  --#{$prefix}offcanvas-border-color: #{$offcanvas-border-color};
  --#{$prefix}offcanvas-box-shadow: #{$offcanvas-box-shadow};

  .offcanvas-header {
    margin: 0 1rem;
    padding: var(--bs-offcanvas-padding-y) 0;
    border-bottom: solid 1px $light;
  }
  .btn-close {
    opacity: 1;
    filter: invert(100%);
  }
}
