.blockquote-section {
  --blockquote-section-bg: #{$body-bg-level-1};
  --blockquote-section-text-color: #{var(--bs-white)};
  --blockquote-section-cite-text-color: #{var(--bs-gray-300)};
  --blockquote-section-padding: 2rem;
  --blockquote-section-border: none;
    border: #{var(--blockquote-section-border)};
    background-color: #{var(--blockquote-section-bg)};

  &__container {
    padding: var(--blockquote-section-padding);
    margin-bottom: 0;
    blockquote {
      color: #{var(--blockquote-section-text-color)};
      margin-bottom: 0.1rem;
      p {
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
    cite {
      color: #{var(--blockquote-section-cite-text-color)};
      &:before {
        content: "— ";
      }
    }
  }
}
