$small-container: 540px;
$medium-container: 720px;
$large-container: 960px;
$xl-container: 1140px;
$xxl-container: 1320px;
$container-max-widths: (
  sm: $small-container,
  md: $medium-container,
  lg: $large-container,
  xl: $xl-container,
  xxl: $xxl-container
);

// Used on article detail pages on the body field for Explo.
.container-medium {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
  @media (min-width: 576px) {
    max-width: $small-container;
  }
  @media (min-width: 768px) {
    max-width: $medium-container;
  }
  @media (min-width: 992px) {
    max-width: 780px;
  }
  @media (min-width: 1200px) {
    max-width: 780px;
  }
  @media (min-width: 1400px) {
    max-width: 780px;
  }

}
