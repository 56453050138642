// Styles come from bootstrap nav tabs found web/themes/custom/oppenheimer/components/bootstrap/components/navs-tabs/navs-tabs.scss


.tabs-section {
  // Tabs.
  .nav-tabs {
    // Hide.
    @include media-breakpoint-down(lg) {
      display: none;
    }
  }
  // Select
  &__select-mobile-wrapper {
    display: none;
    @include media-breakpoint-down(lg) {
      display: block;
    }
  }
}
