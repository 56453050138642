$nav-link-color: #ddd;
$nav-link-hover: var(--bs-gray);
.header {
  position: relative;
  width: 100%;
  z-index: 10;
  border-bottom: solid 1px $light;
  // body.mobile-menu-active & {
  //   position: fixed;
  //   top: 0;
  // }
  .navbar-toggler--mobile,
  .navbar-brand--mobile {
    display: none;
    @include media-breakpoint-down(lg) {
      display: block;
      margin-right: 0;
    }
  }

  .navbar-brand--mobile {
    @include media-breakpoint-down(lg) {
      max-width: 150px;
    }
    svg {
      width: 100%;
    }
  }
  .navbar-brand--desktop {
    display: block;
    width: 180px;
    @include media-breakpoint-down(lg) {
      display: none;
    }
  }

  .navbar {
    padding: 0.3rem;
    .navbar-brand {
      .site-branding {
        min-width: unset;
        max-width: 100%;
      }
    }
    .navbar-toggler {
      width: 45px;
      height: 45px;
      position: relative;
      transform: rotate(0deg);
      padding: 0;
      cursor: pointer;

      &__hamburger-container {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 30px;
        height: 20px;
        transform: translate(-50%, -50%);
      }
      span {
        display: block;
        position: absolute;
        height: 2px;
        width: 30px;
        background: $white;
        opacity: 1;
        border-radius: 10px;
        transform: rotate(0deg);
        transition: 0.25s ease-in-out;
        &:nth-child(1) {
          top: 0px;
          transform-origin: left center;
          body.mobile-menu-active & {
            transform: rotate(45deg);
            top: -2px;
            left: 5px;
          }
        }
        &:nth-child(2) {
          top: 10px;
          transform-origin: left center;
          body.mobile-menu-active & {
            opacity: 0;
            width: 0;
          }
        }
        &:nth-child(3) {
          top: 20px;
          transform-origin: left center;
          body.mobile-menu-active & {
            transform: rotate(-45deg);
            left: 5px;
          }
        }
      }
    }
    // Menu items.
    .menu__wrap {
      .menu__item {
        a {
          font-size: 1rem;
          letter-spacing: 0.5px;
          font-weight: 100;
        }
      }
    }
    #search-form {
      position: relative;
      height: 24px;
      top: -6px;
      max-width: 180px;
      #search-form-input {
        padding-left: 0;
        border: none;
        border-bottom: solid 1px var(--bs-white);
        background: none;
        border-radius: 0;
        color: var(--bs-white);
        caret-color: var(--bs-white);

        &:focus {
          -webkit-box-shadow: none;
          box-shadow: none;
        }
      }
      input[type='search']::-webkit-search-cancel-button {
        -webkit-appearance: none;
      }
    }
    // Desktop styles.
    @include media-breakpoint-up(lg) {
      // Menu root
      .menu__wrap--root {
        justify-content: space-between;
      }

      // Li styles at desktop.
      .menu__item {
        margin-right: 50px;
        &:last-of-type {
          margin-right: 0;
        }
        // Nested li.
        .menu__wrap--nested {
          li {
            margin: 0;
          }
        }
      }

      // Nav links
      .nav-link {
        &.dropdown-toggle {
          position: relative;
          &::after {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            margin: 0;
          }
          &.show {
            &::after {
              transform: translateX(-50%) rotate(-180deg);
            }
          }
        }
      }
      // Dropdowns.
      .dropdown-menu {
        display: inherit;
        visibility: hidden;
        top: 200%;
        left: 50%;
        transform: translateX(-50%);
        opacity: 0;
        transition: 0.5s;
        transition: z-index 0 linear 0.3s;
        border: 1px solid var(--bs-gray-200);
        border-radius: 0;
        &::after {
          position: absolute;
          top: 100%;
          left: 50%;
          transform: translateX(-50%);
        }
        &.show {
          display: inherit;
          opacity: 1;
          visibility: visible;
          top: 45px;
          z-index: 10;
        }
      }
    }
  }
}

// Mobile Styling for mobile menu.
@media (max-width: 991.98px) {
  #navbar-menu {
    position: fixed;
    top: 50px; /* Height of navbar */
    bottom: 0;
    left: -268px;
    width: 268px;
    overflow-y: auto;
    visibility: hidden;
    background-color: $dark;
    transition: transform 0.3s ease-in-out, visibility 0.3s ease-in-out;
    padding: 15px;
    z-index: 1000;
    body.toolbar-vertical & {
      top: 101px;
    }
    &.open {
      visibility: visible;
      transform: translateX(100%);
    }
    #block-mainnavigation--2 {
      min-width: 100%;
    }
    // Menu items.
    .navbar-nav {
      // UL
      .menu__wrap {
        // Nested menu items.
        &--nested {
          border: none;
          border-radius: 0;
          background: none;
          .nav-item {
            padding: 0.4em 0 0 0;
            padding-left: var(--bs-gutter-x, 0.75rem);
            border: 0;
            a {
              color: $white;
              &:hover,
              &:focus {
                background: none;
                color: $nav-link-hover;
              }
            }
          }
        }
      }
      // li
      .menu__item {
        border-bottom: #555 1px solid;
        list-style: none;
        padding: 12px 0 10px 0;
        // a tag
        a {
          position: relative;
          padding: 0;
          font-size: calc(var(--bs-body-font-size) - 0.1em);
          color: $nav-link-color;
          &:hover,
          &:focus {
            color: $nav-link-hover;
          }
          // Dropdown control.
          &.dropdown-toggle {
            .nav-plus {
              position: absolute;
              top: 50%;
              right: 12px;
              transform: translateY(-50%);
              background: $white;
              transition: 0.25s ease-out;
              width: 10px;
              height: 2px;
              opacity: 1;
            }
            .nav-minus {
              position: absolute;
              top: 50%;
              right: 12px;
              transform: translateY(-50%) rotate(-90deg);
              background: $white;
              transition: 0.25s ease-out;
              width: 10px;
              height: 2px;
            }
            &::after {
              display: none;
            }
            // When active.
            &.show {
              .nav-minus {
                opacity: 0;
              }
            }
          }
        }
      }
    }
    // Search form
    #search-form {
      margin-top: 1.7rem;
      padding: 0;
    }
  }
}

.mobile-overlay {
  position: absolute;
  top: 64px;
  height: 100%;
  width: 100%;
  background-color: rgba($black, 0.5);
  backdrop-filter: blur(4px);
  opacity: 0;
  z-index: 0;
  visibility: hidden;
  transition: 0.4s;
  display: none;
  body.mobile-menu-active & {
    visibility: visible;
    opacity: 1;
    z-index: 6;
    display: block;
  }
}
