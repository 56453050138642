// Required for all bootstrap things.
@import "~bootstrap/scss/functions";

// Sass variable overrides should be placed in here for bootstrap if color or typography related.
@import 'base/base';

// More Required for all bootstrap things.
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/utilities";

// Combine all color variations.
$all-colors: map-merge-multiple($theme-colors, $body-bgs, $blues, $indigos, $purples, $pinks, $reds, $oranges, $yellows, $greens, $teals, $cyans, $lights, $grays, $darks);

// Merge all colors to make color classes.
$utilities: map-merge(
  $utilities,
  (
    "color": map-merge(
      map-get($utilities, "color"),
      (
        values: map-merge(
          map-get(map-get($utilities, "color"), "values"),
          (
            $all-colors
          ),
        ),
      ),
    ),
  )
);

// Helpers
@import "bootstrap/scss/helpers";

@import "bootstrap/scss/utilities/api";

// Make all Background colors.
@each $color, $value in $all-colors {
  .bg-#{$color} {
    background-color: $value;
  }
}

@import "~bootstrap/scss/reboot";

// Bootstrap components here with overides.
@import 'components/components';

// Bootstrap forms
@import 'forms/forms';
