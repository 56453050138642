.card-icon {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  &--center {
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  @include media-breakpoint-down(sm) {
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  &__image {
    max-width: 150px;
    &--left {
      margin-right: 1rem;
      @include media-breakpoint-down(sm) {
        margin-left: 0;
        margin-bottom: 1rem;
      }
    }
    &--center {
      margin-bottom: 1rem;
    }
    &--right {
      order: 1;
      margin-left: 1rem;
      @include media-breakpoint-down(sm) {
        margin-left: 0;
        margin-bottom: 1rem;
      }
    }
    img {
      max-width: 100%;
      min-width: 150px;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    &--right {
      order: 0;
    }
  }
}
