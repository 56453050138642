// Experience views grouping.
.experiences-view-group {
  &__title {
    position: sticky;
    top: 0;
    background-color: $body-bg-level-3;
    color: $white;
    padding: 1rem;
    z-index: 10;
  }
}

// Listing groups.
.experiences-view-list-all-events {
  &__title {
    position: sticky;
    top: 58px;
    z-index: 5;
    &__day {
      color: $light-300;
    }
  }
}

// filtered-list-view--experiences
.filtered-list-view--experiences {

  // Flip the image to the right on desktop.
  .card-horizontal-clickable__row {
    flex-direction: row-reverse;
  }
}

.filtered-list-view--home-page-upcoming-events {
  // ...but do not flip the image to the right on desktop on the home page's.
  .card-horizontal-clickable__row {
    flex-direction: initial !important;
  }
}
