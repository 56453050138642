.audio-player {
  .card-body {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .audio-player-caption-bg {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .audio-player-image-framed {
    overflow: hidden;
  }

  .audio-player-image-fitted {
    object-fit: contain;
  }

  .audio-player-image-background {
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: center;
    filter: blur(1em);
    -webkit-filter: blur(1em);
  }
}

