.footer-menu {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  @media (max-width: 767.98px) {
    flex-direction: column;
  }
  &__menu-list {
    li {
      margin-bottom: 0.5em;
    }
    &--first {
      @media (max-width: 767.98px) {
        margin-bottom: 0;
      }
    }
  }
}
