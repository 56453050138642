$audio-player-caption-padding-unit: 0.25em;
$audio-player-caption-margin-unit: 0.5em;

.audio-player {
  &.audio-player-playing {
    .audio-player-captions {
      display: grid;
    }
  }

  .audio-player-captions {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    width: calc(100% - 2 * $audio-player-caption-margin-unit);
    display: none;
    margin: $audio-player-caption-margin-unit;

    .audio-player-caption {
      background-color: rgba(1, 1, 1, 0.8);
      color: white;
      padding: 0 $audio-player-caption-padding-unit;

      &.center {
        text-align: center;
      }

      &:first-child {
        padding-top: $audio-player-caption-padding-unit;
      }

      &:last-child {
        padding-bottom: $audio-player-caption-padding-unit;
      }
    }
  }
}
