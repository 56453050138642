.audio-player-info-bar {
  &__inner {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    border-bottom: solid var(--bs-gray-500) 1px;
    padding-bottom: 0.62rem;
    @include media-breakpoint-down(md) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  &__inner-column-left {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
    @include media-breakpoint-down(md) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__author {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 0.3rem;
    img {
      max-width: 25px;
      height: auto;
    }
    i.bi {
      font-size: 1.5rem;
    }
  }
  // The markup is generated by addtoany module.
  &__social-share {
    a {
      svg {
        fill: var(--bs-gray-300);
        transition: 0.2s;
      }
      &:hover,
      &:focus {
        svg {
          fill: var(--bs-gray-600);
        }
      }

      // addtoany share universal btn.
      &.addtoany_share {
        .a2a_svg {
          background-color: var(--bs-gray-300) !important;
          border-radius: 100% !important;
          transition: 0.2s;
        }
        svg {
          g {
            fill: var(--bs-body-bg);
          }
        }
        &:hover,
        &:focus {
          .a2a_svg {
            background-color: var(--bs-gray-600) !important;
          }
        }
      }
    }
  }
}
