.cardLink {
    height: 100%;
}

a.cardLink:link,
a.cardLink:visited,
a.cardLink:hover,
a.cardLink:active {
    text-decoration: none;
}
