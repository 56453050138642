$footer-font-color: $white;
$footer-link-color: $footer-font-color;
$footer-link-hover-color: var(--bs-gray-600);

@import './footer-cta-links/footer-cta-links';
@import './footer-social-media-links/footer-social-media-links';
@import './footer-menu/footer-menu';
@import './footer-mailing-list/footer-mailing-list';
@import './footer-legal-menu/footer-legal-menu';

.footer {
  position: relative;
  right: 0;
  padding: 2rem 1rem 3rem 1rem;
  background-color: var(--bs-black);
  border-top: solid 1px $light;
  color: $footer-font-color;
  font-size: 0.875rem;
  transition: 0.3s ease-in-out;
  body.mobile-menu-active & {
    right: -268px;
    @include media-breakpoint-up(lg) {
      right: 0;
    }
  }
  a:not(.btn) {
    color: $footer-link-color;
    transition: 0.4s;
    text-decoration: none;
    &:hover,
    &:focus {
      color: $footer-link-hover-color;
    }
  }
  &__logo {
    display: block;
    max-width: 250px;
    @include media-breakpoint-down(md) {
      margin: auto;
      margin-bottom: 25px;
    }
    .site-branding {
      max-width: 100%;
      transition: 0.4s;
    }
    &:hover,
    &:focus {
      .st0 {
        fill: $footer-font-color;
      }
    }
  }
}
