.media-collection-carousel {

  // Top Carousel Slider.
  &__carousel-slider {
    .media-collection-slide {
      text-align: center;
      background-color: $black;
      aspect-ratio: 16/9;
      &--image {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      img {
        max-width: 100%;
        height: auto;
        width: auto;
        @include media-breakpoint-down(lg) {
          max-height: 450px;
        }
        @include media-breakpoint-down(md) {
          max-height: 350px;
        }
      }
      // Remote videos youtube and vimeo
      &--remote-video {
        position: relative;
      }
      //Brightcove
      &--brightcove {
        .vjs-fluid {
          z-index: 1;
        }
      }
    }
  }

  &__carousel-thumbnails-controls {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 1.5rem 0;

    .swiper-pagination {
      position: relative;
      transform: none !important;
      left: inherit !important;
      bottom: inherit !important;
      .swiper-pagination-bullet {
        border: solid 1px $white;
        opacity: 1;
        background-color: transparent;
        width: 20px;
        height: 20px;

        &.swiper-pagination-bullet-active {
          background-color: $white;
        }
      }
    }
  }

  // Prev and Next controls
  &__carousel-thumbnails-control-prev,
  &__carousel-thumbnails-control-next {
    min-width: 20px;
    min-height: 20px;
    transition: 0.2s;
    svg {
      width: 100%;
      height: 100%;
    }
  }
}
