.person-detail-page {

  .field--name-field-headshot {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .field--name-field-experiences {
    display: grid;
    gap: 1.5rem;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  }
}
