// Filtered list Paragraph
.paragraph-component--filtered-list {
  .viewsreference--view-title {
    display: none;
  }
}


.filtered-list-view {
  // Header content wrapper
  &__header-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
  }
  // Exposed form.
  .views-exposed-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    ul {
      list-style-type: none;
      padding: 0;
      ul {
        padding-left: 0.8rem;
      }
      li {
        label {
          display: inline;
        }
      }
    }

    details {

      legend {
        display: none;
      }

      .explo-details__content {
        max-height: 21.85rem;
        overflow-y: scroll;
      }
      .explo-details__content::-webkit-scrollbar {
        background-color: transparent;
      }
      .explo-details__content::-webkit-scrollbar-thumb {
        background-color: $dark;
        border-radius: 1.2rem;
      }


      //  Chosen Filters inside detail elements
      .form-item-keywords {
        min-height: 15rem;
      }
      .shs-container {
        display: none;
      }
      .chosen-container {
        padding: 0;
        .chosen-choices {
          padding: 0.8rem 0.8rem;
        }
      }
      .chosen-choices {
        padding: 0.8rem 0.8rem;

        .search-choice {
          background-color: $light-600;
          color: $white;
          box-shadow: none;
          border: none;
          background-image: none !important;
          padding: 5px 20px 6px 5px;
          .search-choice-close {
            background-image: none !important;
            background: none;
            text-decoration: none;
            color: white;
            top: 50%;
            transform: translateY(-50%);
            &:after {
              content: 'X';
              font-size: 0.8rem;
              font-weight: bold;
            }
          }
        }

      }
      .chosen-drop {
        left: 0;
      }
      .chosen-results {
        max-height: 10rem;
      }
    }
    // Text field.
    .js-form-type-textfield {
      label {
        margin-bottom: 0.5rem;
      }
    }
    .form-text {
      min-width: 100%;
      padding: 0.9rem;
    }

    .form-item-date {
      position: relative;
      &:after {
        content: '';
        position: absolute;
        right: 50px;
        top: 0;
        background-color: $body-bg-light;
        width: 1px;
        height: 100%;
      }
    }
    input[type=date] {
      background-color: transparent;
      border: solid 2px $body-bg-light;
      margin: 0;
      &::-webkit-calendar-picker-indicator {
        cursor: pointer;
        opacity: 1;
        filter: invert(1);
      }
    }

    .form-actions {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      input,
      button {
        &:first-of-type {
          @extend .btn, .btn-white;
        }
      }
      input[data-drupal-selector="edit-reset"],
      button[data-drupal-selector="edit-reset"] {
        background: transparent;
        border: none;
        color: $primary;
        padding: 0;

        &:hover,
        &:focus {
          color: darken($primary, 15%);
        }
      }
    }
  }
}
