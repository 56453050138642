.audio-player {
  .audio-controls {
    flex-wrap: wrap-reverse;
  }

  .audio-progress {
    background-color: $body-bg-level-3;

    .progress {
      background-color: inherit !important;
      height: calc($progress-border-radius * 2);
    }

    .audio-progress-stats {
      @extend .px-3;

      background-color: $card-bg;
    }
  }
}
