.explo-details {
  background-color: $dark;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2);
  &[open] {
    .explo-details__icon {
      transform: rotate(180deg);
    }
    summary {
      border-bottom: solid 1px $light;
    }
  }
  // Hide default arrow.
  summary::marker, /* Latest Chrome, Edge, Firefox */
  summary::-webkit-details-marker /* Safari */ {
    display: none;
    font-size: 0;
  }
  summary {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1.2rem;
    color: var(--bs-white);
    margin-bottom: 0;
    background-color: $dark;
    border-bottom: solid 1px transparent;
  }

  &__icon {
    margin-left: auto;
    transform: rotate(0deg);
    transition: 0.4s;
  }

  &__content {
    padding: 1.2rem;
    background-color: $body-bg-level-1;
  }
}
