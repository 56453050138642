// The lightbox plugin is https://github.com/biati-digital/glightbox
// The inital styling comes from ttps://cdn.jsdelivr.net/npm/glightbox/dist/css/glightbox.min.css
// For storybook the cdn is loaded in web/themes/custom/oppenheimer/.storybook/preview-head.html
// For drupal loaded in as lib

.gslider {
  .gslide-image {
    img {
      @include media-breakpoint-down(sm) {
        max-height: 60vh;
      }
    }
  }
  .gslide-description {
    background-color: $body-bg;
    .gslide-title {
      color: var(--bs-gray-100);
    }
    .imgage-caption-data__title {
      color: var(--bs-gray-100);
    }
  }
}
