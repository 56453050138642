.simple-teaser {
  position: relative;
  &__image-wrapper {
    display: block;
    position: relative;
    display: block;
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      box-shadow: inset 0px 0px 0px 2px $white;
      z-index: 1;
      transition: 0.2s;
    }
    .media-generic-image__figure {
      display: block;
      margin: 0;
    }
    img {
      aspect-ratio: 16/9;
      height: auto;
      max-height: 450px;
      width: 100%;
      object-fit: cover;
    }

    &:hover,
    &:focus {
      &:after {
        box-shadow: inset 0px 0px 0px 4px $white;
      }
    }
  }
  &__link {
    &:hover,
    &:focus {
      color: var(--bs-link-hover-color) !important;
    }
  }
}


.simple-teaser-horizontal {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 1rem;

  &__image-wrapper {
    position: relative;
    display: block;
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      box-shadow: inset 0px 0px 0px 2px $white;
      transition: 0.2s;
    }
    .media-generic-image__figure {
      margin: 0;
    }
    img {
       height: auto;
    }
    &:hover,
    &:focus {
      &:after {
        box-shadow: inset 0px 0px 0px 4px $white;
      }
    }
  }
}
