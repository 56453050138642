.card--brightcove,
.card--remote-video {
  a.card-title {
    text-underline-offset: 0.2rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    &:hover,
    &:focus {
      color: var(--bs-card-color);
    }
  }
}
