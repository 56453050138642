.eclipse-countdown-header {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: solid 1px $light;
  background-color: $black;
  color: $white;

  @include media-breakpoint-down(lg) {
    font-size: 14px;
  }

  .field--name-field-countdowns {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 4rem;
    padding: 1rem;
    @include media-breakpoint-down(lg) {
      text-align: center;
      gap: 1rem;
    }
  }

  .count-down__timer {
    font-size: 1rem;
  }
}

.count-down {

  &__timer {
    @include media-breakpoint-down(lg) {
      font-size: 1rem;
    }

    .desktop-text {
      display: inline-block;
      @include media-breakpoint-down(lg) {
        display: none;
      }
    }

    .mobile-text {
      display: none;
      @include media-breakpoint-down(lg) {
        display: inline-block;
      }
    }
  }
}
